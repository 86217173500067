.ck-editor__editable_inline {
  min-height: 400px;
  width: 1200px;
}

@media only screen and (max-width: 1400px) {
  .ck-editor__editable_inline {
    width: unset;
  }
}

.ck-rounded-corners .ck.ck-balloon-panel, .ck.ck-balloon-panel.ck-rounded-corners {
  z-index: 500000000;
}