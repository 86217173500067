.dropzone_modal_container {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.226);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5000;
}

.dropzone_modal {
    position: absolute;
    background-color: white;
    border-radius: 5px;
    width: 550px;
    padding: 10px;
}

.dropzone_header {
    margin: 10px 30px;
    font-size: 1rem;
}

.dropzone_modal_content {
    padding: 10px 20px;
    max-height: 450px;
    overflow-y: scroll;
}

.dropzone_container {
    position: relative;
    width: fit-content;
    height: fit-content;
    /* margin-right: 10px;
    margin-bottom: 10px; */
    border-radius: 5px;
    background-color: #fff;
}

.dropzone_label {
    width: 100%;
    height: 100%;
    min-height: 250px;
    border: 4px dashed #e0e0e0;
    position: relative;
    cursor: pointer;
    border-radius: 5px;
    display: block;
    overflow: hidden;
}

.dropzone_text {
    width: 90%;
    text-align: center;
    margin: 10px auto 10px;
    font-weight: 600;
}

.dropzone_input {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
    inset: 0;
    cursor: pointer;
}

.dropzone_icon {
    width: 100%;
    display: flex;
    justify-content: center;
}

.dropzone_image_container {
    position: absolute;
    bottom: 10px;
    left: 10px;
    width: 100px;
    height: 50px;
}

.dropzone_image_external_container {
    position: relative;
    width: 200px;
    height: 100px;
    margin: 0 10px 10px 0;

}

.dropzone_image_external_container .dropzone_image_name {
    text-align: center;
    width: 100%;
}

.dropzone_dialog_image_container {
    margin: 10px 0;
    position: relative;
    width: 150px;
    height: 100px;
}

.dropzone_image_container .dropzone_image,
.dropzone_image_container .dropzone_image_placeholder {
    height: 50px;
}

.dropzone_image_external_container .dropzone_image,
.dropzone_image_external_container .dropzone_image_placeholder {
    height: 100px;
}

.dropzone_dialog_image_container .dropzone_image,
.dropzone_dialog_image_container .dropzone_image_placeholder {
    height: 100px;
}

.dropzone_image,
.dropzone_image_placeholder {
    position: absolute;
    width: 100%;
    object-fit: cover;
    inset: 0;
    border-radius: 5px;
    box-shadow: 0 0 5px grey;
}

.dropzone_image_placeholder {
    background-color: #dadada;
}


.dropzone_image_delete {
    display: none;
    position: absolute;
    top: -10px;
    right: -10px;
    cursor: pointer;
    z-index: 50;
    border-radius: 50%;
    padding: 3px 5px;
    border: 1px solid black;
}

.dropzone_image_delete_no_image {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    z-index: 50;
    background-color: transparent;
    border: none;
    height: 100%;
}

.dropzone_image_name {
    position: absolute;
    bottom: 0;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 140px;

}

.dropzone_image_external_container .dropzone_image_name {
    text-align: center;
    width: 100%;
    position: absolute;
    bottom: 0;
}

.dropzone_image:hover~.dropzone_image_delete,
.dropzone_image_delete:hover,
.dropzone_image_placeholder:hover~.dropzone_image_delete {
    display: block;
}

.dropzone_image_delete:hover~.dropzone_image_fade {
    background-color: white;
    opacity: 0.3;
    position: absolute;
    inset: 0;
}

.dropzone_preview_text {
    margin: 20px 0 0;
}

.dropzone_button_container {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}