.disabled_button,
.disabled_promote_button {
    position: relative;
}

.disabled_button:hover:after {
    position: absolute;
    top: -10px;
    left: 0;
    content: 'Cannot Select Test or Promote While Revalidating';
    white-space: nowrap;
    color: #970a0a;
}

.disabled_promote_button:hover:after {
    position: absolute;
    top: -10px;
    left: 0;
    content: 'Staging Data is Empty for Selected Pages';
    white-space: nowrap;
    color: #970a0a;
}